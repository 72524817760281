export default [
    {
        path: '/student/list',
        name: 'student-list',
        component: () => import('@/views/student/List.vue'),
        meta: {
            pageTitle: 'Student Management'
        },
    },
    {
        path: '/student/add/',
        name: 'student-add',
        component: () => import('@/views/student/Detail.vue'),
        meta: {
            pageTitle: 'Add Student',
            breadcrumb: [
                {
                    text: 'Student Management',
                    to: {name: 'student-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/edit/:id',
        name: 'student-edit',
        component: () => import('@/views/student/Detail.vue'),
        meta: {
            pageTitle: 'Edit Student',
            breadcrumb: [
                {
                    text: 'Student Management',
                    to: {name: 'student-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
]
