// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
    app: {
        appName: 'University Seekers',
        appLogoImage: require('@/assets/images/logo/logo.svg'),
        appLogoImageLight: require('@/assets/images/logo/logo_light.svg'),
        appPanelKey: 'QynF2l@0pmMo6aYj93YVrU5lJs#LK8NS!4NhL1',
        appApiUrl: 'https://api.universityseekers.com', //'http://127.0.0.1:5001', //'https://api.universityseekers.com', //https://uni-api.shoresitedesigns.com
        appWebSiteUrl: 'https://universityseekers.com', //'http://127.0.0.1:5002', //'https://universityseekers.com', //https://uni-web.shoresitedesigns.com
        appIsDebug: false
    },
    layout: {
        isRTL: false,
        skin: 'semi-dark', // light, dark, bordered, semi-dark
        routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
        type: 'vertical', // vertical, horizontal
        contentWidth: 'full', // full, boxed
        menu: {
            hidden: false,
            isCollapsed: false,
        },
        navbar: {
            type: 'sticky', // static , sticky , floating, hidden
            backgroundColor: '', // BS color options [primary, success, etc]
        },
        footer: {
            type: 'hidden', // static, sticky, hidden
        },
        customizer: true,
        enableScrollToTop: true,
    },
}
