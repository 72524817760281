export default [
    {
        path: '/cms/language/list',
        name: 'cms-language-list',
        component: () => import('@/views/cms/language/List.vue'),
        meta: {
            pageTitle: 'Language Management'
        },
    },
    {
        path: '/cms/language/add/',
        name: 'cms-language-add',
        component: () => import('@/views/cms/language/Detail.vue'),
        meta: {
            pageTitle: 'Add Language',
            breadcrumb: [
                {
                    text: 'Language Management',
                    to: {name: 'cms-language-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/language/edit/:id',
        name: 'cms-language-edit',
        component: () => import('@/views/cms/language/Detail.vue'),
        meta: {
            pageTitle: 'Edit Language',
            breadcrumb: [
                {
                    text: 'Language Management',
                    to: {name: 'cms-language-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/category/list',
        name: 'cms-category-list',
        component: () => import('@/views/cms/category/List.vue'),
        meta: {
            pageTitle: 'Category Management'
        },
    },
    {
        path: '/cms/category/add/',
        name: 'cms-category-add',
        component: () => import('@/views/cms/category/Detail.vue'),
        meta: {
            pageTitle: 'Add Category',
            breadcrumb: [
                {
                    text: 'Category Management',
                    to: {name: 'cms-category-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/category/edit/:id',
        name: 'cms-category-edit',
        component: () => import('@/views/cms/category/Detail.vue'),
        meta: {
            pageTitle: 'Edit Category',
            breadcrumb: [
                {
                    text: 'Category Management',
                    to: {name: 'cms-category-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/category/images/:id',
        name: 'cms-category-images',
        component: () => import('@/views/cms/category/Images.vue'),
        meta: {
            pageTitle: 'Category Images',
            breadcrumb: [
                {
                    text: 'Category Management',
                    to: {name: 'cms-category-list'}
                },
                {
                    text: 'Images',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/content/list',
        name: 'cms-content-list',
        component: () => import('@/views/cms/content/List.vue'),
        meta: {
            pageTitle: 'Content Management'
        },
    },
    {
        path: '/cms/content/add/',
        name: 'cms-content-add',
        component: () => import('@/views/cms/content/Detail.vue'),
        meta: {
            pageTitle: 'Add Content',
            breadcrumb: [
                {
                    text: 'Content Management',
                    to: {name: 'cms-content-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/content/edit/:id',
        name: 'cms-content-edit',
        component: () => import('@/views/cms/content/Detail.vue'),
        meta: {
            pageTitle: 'Edit Content',
            breadcrumb: [
                {
                    text: 'Content Management',
                    to: {name: 'cms-content-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/content/images/:id',
        name: 'cms-content-images',
        component: () => import('@/views/cms/content/Images.vue'),
        meta: {
            pageTitle: 'Content Images',
            breadcrumb: [
                {
                    text: 'Content Management',
                    to: {name: 'cms-content-list'}
                },
                {
                    text: 'Images',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/menu/list',
        name: 'cms-menu-list',
        component: () => import('@/views/cms/menu/List.vue'),
        meta: {
            pageTitle: 'Menu Management'
        },
    },
    {
        path: '/cms/menu/add/',
        name: 'cms-menu-add',
        component: () => import('@/views/cms/menu/Detail.vue'),
        meta: {
            pageTitle: 'Add Menu',
            breadcrumb: [
                {
                    text: 'Menu Management',
                    to: {name: 'cms-menu-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/menu/edit/:id',
        name: 'cms-menu-edit',
        component: () => import('@/views/cms/menu/Detail.vue'),
        meta: {
            pageTitle: 'Edit Menu',
            breadcrumb: [
                {
                    text: 'Menu Management',
                    to: {name: 'cms-menu-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/slider/list',
        name: 'cms-slider-list',
        component: () => import('@/views/cms/slider/List.vue'),
        meta: {
            pageTitle: 'Slider Management'
        },
    },
    {
        path: '/cms/slider/add/',
        name: 'cms-slider-add',
        component: () => import('@/views/cms/slider/Detail.vue'),
        meta: {
            pageTitle: 'Add Slider',
            breadcrumb: [
                {
                    text: 'Slider Management',
                    to: {name: 'cms-slider-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/slider/edit/:id',
        name: 'cms-slider-edit',
        component: () => import('@/views/cms/slider/Detail.vue'),
        meta: {
            pageTitle: 'Edit Slider',
            breadcrumb: [
                {
                    text: 'Slider Management',
                    to: {name: 'cms-slider-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cms/settings',
        name: 'cms-settings',
        component: () => import('@/views/cms/settings/Detail.vue'),
        meta: {
            pageTitle: 'Settings'
        },
    },
]
