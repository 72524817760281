export default [
    {
        path: '/university/list',
        name: 'university-list',
        component: () => import('@/views/university/List.vue'),
        meta: {
            pageTitle: 'University Management'
        },
    },
    {
        path: '/university/add/',
        name: 'university-add',
        component: () => import('@/views/university/Detail.vue'),
        meta: {
            pageTitle: 'Add University',
            breadcrumb: [
                {
                    text: 'University Management',
                    to: {name: 'university-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/university/edit/:id',
        name: 'university-edit',
        component: () => import('@/views/university/Detail.vue'),
        meta: {
            pageTitle: 'Edit University',
            breadcrumb: [
                {
                    text: 'University Management',
                    to: {name: 'university-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
]
