export default [
    {
        path: '/partner/list',
        name: 'partner-list',
        component: () => import('@/views/partner/List.vue'),
        meta: {
            pageTitle: 'Partner Management'
        },
    },
    {
        path: '/partner/edit/:id',
        name: 'partner-edit',
        component: () => import('@/views/partner/Detail.vue'),
        meta: {
            pageTitle: 'Partner',
            breadcrumb: [
                {
                    text: 'Partner Management',
                    to: {name: 'partner-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
]
